<template>
  <div  @keydown.enter="search" >
    <BreadCrumbs>خانه > دسته بندی شرکت ها</BreadCrumbs>
    <transition enter-active-class="animate__animated animate__fadeIn" appear>
      <div >
    <h1 style="font-size: 28px" class="mt-8 mb-2 primary--text text-center font-weight-regular" >دسته‌بندی شرکت‌ها</h1>
    <h2 class="catssubtitle ma-0 font-weight-regular">بانک جامع اطلاعاتی از شرکت‌ها و مشاغل مختلف</h2>


    <div class="catssearch">
    <v-text-field
        label="عبارت مورد نظر خود را جستجو کنید ..."
        style="width: 100%;border-radius: 8px;"
        dark
        solo
        v-model="str"
        height="58"
        hide-details
    >
      <template v-slot:append>
        <div class="mt-2" style="cursor: pointer;" @click="search">
          <img style="filter: drop-shadow(0px 2px 2px #000);" src="../assets/image/Main/serach-icon.png" width="80%" />
        </div>
      </template>
    </v-text-field>
    </div>
        <div class="catssearchprp flex-wrap flex-sm-nowrap">
        <v-select
            label="انتخاب دسته بندی"
            style="width: 100%;border-radius: 8px;"
            class="ma-1"
            v-model="selectedCatId"
            :items="cats"
            solo
            item-text="title"
            item-value="id"
            color="#f4f4f4"
            height="45"
            hide-details
        >
          <template v-slot:append>
            <img src="../assets/image/Cat/icon-down.png" height="11.5" width="20"/>
          </template>
          <template v-slot:no-data>
            <div class="d-flex justify-center">
              <p class="ma-0 my-2">در حال بارگزاری</p>
            </div>
          </template>
        </v-select>
          <v-select
              label="استان"
              style="width: 100%;border-radius: 8px;"
              solo
              v-model="selectedprovinceId"
              :items="provinces"
              class="ma-1"
              item-text="name"
              item-value="id"
              color="#f4f4f4"
              height="45"
              hide-details
          >
            <template v-slot:append>
              <img src="../assets/image/Cat/icon-down.png" height="11.5" width="20"/>
            </template>
            <template v-slot:no-data>
              <div class="d-flex justify-center">
                <p class="ma-0 my-2">در حال بارگزاری</p>
              </div>
            </template>
          </v-select>
          <v-select
              label="شهر"
              style="width: 100%;border-radius: 8px;"
              solo
              v-model="selectedcityId"
              :items="cities"
              class="ma-1"
              item-text="name"
              item-value="id"
              color="#f4f4f4"
              height="45"
              hide-details
          >
            <template v-slot:append>
              <img src="../assets/image/Cat/icon-down.png" height="11.5" width="20"/>
            </template>
            <template v-slot:no-data>
              <div class="d-flex justify-center">
                <p class="ma-0 my-2">ابتدا استان را انتخاب کنید</p>
              </div>
            </template>
          </v-select>
        </div>

        <SwiperInCats class="mb-8" :selected="selectedCatId" @selectedCat="swiperselect($event)" ></SwiperInCats>

<!--    <v-container class="d-flex flex-wrap justify-center pa-0">
        <div @click="selectedCatId = cat.id" class="catitem" :class="{'activecat' : cat.id == selectedCatId}" v-for="cat in cats" :key="cat.id">
          <div>
          <img v-if="cat.icon" class="mt-4" :src="$store.state.thumbnail + cat.icon"/>
          <p class="primary&#45;&#45;text ma-0 mt-4" style="font-size: 22px">{{cat.title}}</p>
          </div>
        </div>
    </v-container>-->

    <v-container class="catscontanier">
      <div class="catscontant" v-if="works" >

        <p v-if="works.length>0" class="primary--text mb-2" style="font-size: 14px" >لیستی از شرکت های ثبت شده در سامانه ایران آی سیب در دسته <span style="font-weight: bold">{{catstitle}}</span>
          موجود است. جهت مشاهده اطلاعات کامل روی گزینه مشاهده کلیک نمائید.
        </p>
        <p v-if="works.length>0" class="defaultcolor ma-0 d-flex align-center" style="font-size: 14px">
          <v-icon class="ml-1" >mdi-check</v-icon>
          ترتیب چینش شرکت ها براساس حروف الفبا می باشد (به غیر از اسپانسرهای
          ویژه ایران آی سیب)
        </p>
        <div v-if="works.length>0">
        <div class="catscontantitem flex-lg-nowrap" v-for="work in works" :key="work.id" >
          <div class="calendaritemimage">
          <img v-if="work.image" :src="$store.state.thumbnail + work.image" width="100%" style="object-fit: cover;"/>
            <img alt="لوگوی ایران آی سیب" title="ایران آی سیب" v-else src="../assets/image/Header/logo.png" style="max-width: 70%;opacity: 0.2"/>
          </div>
          <div class=" mr-2 mb-3 mb-md-0 mr-lg-2 cont1size">
            <div class="d-flex justify-space-between flex-wrap"><p class="propertieskey">نام شرکت / مجموعه :</p><span v-if="work.title" class="propertiesvalue d-flex align-center">{{work.title}}  <img
                src="../assets/image/Cat/bluetick.png" v-if="work.special" class="mr-1" height="18" width="18" alt="ویژه"/></span><span v-else class="propertiesvalue" style="font-weight: normal">ثبت نشده</span></div>
            <div class="my-2 d-flex justify-space-between flex-wrap"><p class="propertieskey">حوزه فعالیت :</p><span v-if="work.catTitle" class="propertiesvalue">{{work.catTitle}}</span><span v-else class="propertiesvalue" style="font-weight: normal">ثبت نشده</span></div>
            <div class="d-flex justify-space-between flex-wrap" ><p class="propertieskey">نام برند تجاری :</p><span v-if="work.brand" class="propertiesvalue">{{work.brand}}</span><span v-else class="propertiesvalue" style="font-weight: normal">ثبت نشده</span></div>
          </div>
          <div class="mr-2 mb-3 mb-md-0 mr-lg-6 cont2size">
            <div class="d-flex justify-space-between flex-wrap"><p class="propertieskey">شماره تلفن ثابت :</p><span v-if="work.fixedNumber" class="propertiesvalue">{{work.fixedNumber}}</span><span v-else class="propertiesvalue" style="font-weight: normal">ثبت نشده</span></div>
            <div class="my-2 d-flex justify-space-between flex-wrap"><p class="propertieskey">ایمیل شرکت :</p><a v-if="work.coEmail" style="text-decoration: none" :href="'mailto:'+work.coEmail" class="propertiesvalue textlinkhover">{{ work.coEmail }}</a><span v-else class="propertiesvalue" style="font-weight: normal">ثبت نشده</span></div>
            <div class="d-flex justify-space-between flex-wrap"><p class="propertieskey">آدرس سایت :</p><a v-if="work.siteUrl" style="text-decoration: none" :href="'https://'+work.siteUrl" target="_blank" class="propertiesvalue textlinkhover">{{
                work.siteUrl
              }}</a><span v-else class="propertiesvalue" style="font-weight: normal">ثبت نشده</span></div>
          </div>
          <div class="morecontentbtn">
            <router-link :to="{name : 'company',params : {id : work.userId,title:work.title}}" custom v-slot="{navigate,href}" >
          <v-btn
              class="btn d-block mr-auto ml-lg-1 mb-2 mb-lg-0"
              style="padding-top: 9px !important;"
              @click="navigate"
              :href="href"
              role="link"
          >
            مشاهده اطلاعات
          </v-btn>
            </router-link>
          </div>
        </div>
          <div>
            <v-btn
                class="btn mt-2 v-btn--block"
                style="font-size: 16px !important;"
                @click="nextpage"
                :loading="loading"
                v-if="showmore"
            >
              نمایش شرکت های بیشتر
            </v-btn>
          </div>
        </div>
        <p v-if="!workloading & works.length===0" class="text-center mt-10" >موردی جهت نمایش وجود ندارد</p>
        <div v-if="workloading" class="d-flex justify-center">
          <div class="lds-default"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
        </div>

      </div>
    </v-container>
      </div>
    </transition>
  </div>
</template>

<script>
import BreadCrumbs from "@/components/BreadCrumbs";
import SwiperInCats from "@/components/SwiperInCats";
import axios from "axios";
export default {
  name: 'App',

  data() {
    return {
      cats:[],
      selectedCatId : null,
      submittedcatId : null,
      selectedprovinceId : null,
      submittedprovincetId : null,
      selectedcityId : null,
      submittedcitytId : null,
      provinces : [],
      cities : [],
      works : [],
      str : '',
      submittedstr:null,
      savedstr : '',
      catstitle : 'همه دسته بندی ها',
      workloading : true,
      showmore :false,
      page : 1,
      loading : false,
    }
  },
  metaInfo() {
    return {
      title:'دسته‌بندی شرکت‌ها',
      link: [{rel: 'canonical', href: this.$store.state.url+'/cats'}]
    }
  },
  components : {
    BreadCrumbs,
    SwiperInCats
  },
  created() {
    this.str = this.$route.query.q;
    if(this.$route.query.cat){
      this.selectedCatId = this.$route.query.cat.toString();
    }
    this.selectedprovinceId = this.$route.query.province;
    this.selectedcityId = this.$route.query.city;

    axios.get(this.$store.state.api + 'cats').then(response => {
      this.cats = response.data.data;

      for(let item in this.cats){
        if(this.cats[item].id == this.$route.query.cat){
          if(this.$route.query.cat != null){
            this.catstitle=this.cats[item].title;
          } else {
            this.catstitle='همه دسته بندی ها';
          }
        }
      }
    })
    axios.get(this.$store.state.api + 'getworks',
        {params : {
            page : this.page,
            catId:this.selectedCatId,
            str : this.str,
            provinceId : this.selectedprovinceId,
            cityId : this.selectedcityId,
          }}
    ).then(response => {
      this.works = response.data.data;
      this.submittedcatId = this.selectedCatId;
      this.submittedstr = this.str;
      this.submittedprovincetId = this.selectedprovinceId;
      this.submittedcitytId = this.selectedcityId;
      axios.get(this.$store.state.api + 'getworks', {
        params: {
          page : this.page + 1,
          catId : this.selectedCatId,
          str : this.str,
          provinceId : this.selectedprovinceId,
          cityId : this.selectedcityId
        }
      }).then(response => {
        if(response.data.data.length==0){
          this.showmore=false;
        } else {
          this.showmore=true;
        }
      })
      this.workloading = false;
    })

    axios.get(this.$store.state.api + 'getprovince').then(response => {
      this.provinces = response.data.data;
    })

  },
  watch : {
    '$route'() {

      this.str = this.$route.query.q;
      this.selectedCatId = this.$route.query.cat;
      this.selectedprovinceId = this.$route.query.province;
      this.selectedcityId = this.$route.query.city;
      this.showmore=false;
      this.page = 1;

      this.search();

      for(let item in this.cats){
        if(this.cats[item].id == this.$route.query.cat){
          if(this.$route.query.cat == null){
            this.catstitle='همه دسته بندی ها';
          } else {
            this.catstitle=this.cats[item].title;
          }
        }
      }

    },

    '$route.query.cat'() {
        if(!this.$route.query.cat){
          this.catstitle='همه دسته بندی ها';
        }
       },
    selectedprovinceId(newval , oldval){
      if(newval!==oldval&&this.selectedprovinceId>0){
        this.cities = [];
        axios.get(this.$store.state.api + 'getcity',{params : {id:this.selectedprovinceId}}).then(response => {
          this.cities = response.data.data;
        })
      }
    },
  },
  methods : {
    search(){
      this.$router.push({query:{q : this.str,cat:this.selectedCatId,province:this.selectedprovinceId,city:this.selectedcityId}}).catch(()=>{});
      this.workloading = true;
      this.works=[];
      axios.get(this.$store.state.api + 'getworks',
          {params : {
            page :this.page,
            catId:this.selectedCatId,
              str : this.str,
              provinceId : this.selectedprovinceId,
              cityId : this.selectedcityId,
          }}
      ).then(response => {
        this.works = response.data.data;
        this.submittedcatId = this.selectedCatId;
        this.submittedstr = this.str;
        this.submittedprovincetId = this.selectedprovinceId;
        this.submittedcitytId = this.selectedcityId;

        axios.get(this.$store.state.api + 'getworks', {
          params: {
            page : this.page + 1,
            catId : this.selectedCatId,
            str : this.str,
            provinceId : this.selectedprovinceId,
            cityId : this.selectedcityId
          }
        }).then(response => {
          if(response.data.data.length==0){
            this.showmore=false;
          } else {
            this.showmore=true;
          }
        })
        this.workloading = false;
      })
    },
    swiperselect(event){
      this.selectedCatId = event;
    },
    nextpage(){
      this.loading=true;
      this.page = this.page + 1;
      axios.get(this.$store.state.api + 'getworks', {
        params: {
          page : this.page,
          catId : this.submittedcatId,
          str : this.submittedstr,
          provinceId : this.submittedprovincetId,
          cityId : this.submittedcitytId
        }
      }).then(response => {
        this.works.push(...response.data.data);
        axios.get(this.$store.state.api + 'getworks', {
          params: {
            page : this.page + 1,
            catId : this.submittedcatId,
            str : this.submittedstr,
            provinceId : this.submittedprovincetId,
            cityId : this.submittedcitytId
          }
        }).then(response => {
          if(response.data.data.length==0){
            this.showmore=false;
            this.loading=false;
          } else {
            this.showmore=true;
            this.loading=false;
          }
        })

      })
    }
  },


};
</script>

<style lang="scss">

@import "../assets/css/loading";

.catssearch{
  display: block;
  padding: 0 5px;
  max-width: 870px;
  margin: 20px auto 18px;
}

.catssearchprp {
  display: flex;
  padding: 0 5px;
  max-width: 870px;
  margin: 20px auto 18px;
}

.catssearchprp .v-label{
  color: #570654 !important;
  font-size: 16px ;
  height: 50px !important;
}

.catssearchprp .v-input{
  color: #570654 !important;
  font-size: 16px !important;
}

.catssearchprp .v-input__slot {
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08) !important;
  padding: 0 16px !important;
}

.catssearchprp .theme--light.v-input input, .theme--light.v-input textarea{
  color: #570654 !important;
}

.catssearchprp .v-list * , ::before , ::after {
  color: #570654 !important;
}

.catssearchprp .theme--light.v-select .v-select__selections{
  color: #570654 !important;
  line-height : 30px;
  background-color: #f0f0f0;
}

.catssearchprp .v-input__slot{
  background-color: #f0f0f0 !important;
}

.catssearch .v-input__slot{
  background: #570654 !important;
}

.catssearch .v-label{
  color: #fff !important;
  font-size: 18px ;
  height: 40px !important;
}

.catssearch .v-input{
  color: #fff !important;
  font-size: 18px !important;
  text-shadow: 0px 3px 2px rgba(0, 0, 0, 0.6) !important;
}

.catssearch .v-input__slot {
  box-shadow: none !important;
  padding: 0 20px !important;
}

.catssubtitle{
  font-size: 17px;
  color: #aaaaaa;
  text-align: center;
}

.catitem{
  width: 220px;
  height: 234px;
  margin: 12px 25.6px;
  background-color: #f9f9f9;
  border-radius: 10px;
  display: flex;
  justify-content: center;
  align-items: center;
  text-align: center;
  user-select: none;
  cursor: pointer;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08);
}

.catscontant{
  min-height: 140px;
  background-color: #f9f9f9;
  border-radius: 10px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08);
  margin-bottom: 140px;
  padding: 28px 32px 22px 32px;
}

/*@media only screen and (min-width: 600px){
  .catscontant{
    padding: 28px 32px 22px 32px;
  }
}*/

.catscontantitem {
  margin: 24px 0;
  border-radius: 8px;
  background-color: #fdfdfd;
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-evenly;
  min-height: 134px;
  padding: 4px 6px;
  box-shadow: 0px 0px 4px rgba(0,0,0,0.08);
}

.cont1size {
  width: 100%;
}

.cont2size {
  width: 100%;
}





.calendaritemimage{
  border : 3px solid #f9f9f9;
  width: 200px;
  height: 200px;
  overflow: hidden;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media only screen and (min-width: 960px) {

  .calendaritemimage {
    width: 125px ;
    height: 125px ;
  }

}


@media only screen and (min-width: 1024px) {

  .catscontantitem {
    flex-wrap: nowrap;
  }



  .cont1size {
    width: 436px;
  }

  .cont2size {
    width: 380px;
  }

  .calendaritemimage {
    min-width: 125px;
    min-height: 125px;
  }

}

@media only screen and (min-width: 960px) {

  .catscontantitem {
    justify-content: center;
  }

  .cont1size {
    width: 600px;
  }

  .cont2size {
    width: 600px;
  }

}

.morecontentbtn{
  margin-right: 10px;
}

@media only screen and (min-width: 1191px) {
  .morecontentbtn {
    margin-right: 50px;
  }
}

.catscontanier{
  padding: 0px 5px;
}

@media only screen and (min-width: 600px) {
  .catscontanier{
    padding: 0px 25.6px;
  }
}

.activecat {
  background-color: #e1e1e1;
}


</style>
